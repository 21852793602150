/*
|---------------------------------------------------------------
| MIN-HEIGHTS
|---------------------------------------------------------------
| Generate min-heights
|
|
*/

/*
|----------------------
| Min-heights setting
|----------------------
| - Generated classes: .mh-{size}
|
*/
$min-height-xs :   20vh;
$min-height-sm :   30vh;
$min-height-md :   40vh;
$min-height-lg :   50vh;
$min-height-xl :   75vh;
$min-height-full : 100vh;