//------- BUTTONS

.btn-link{
    position: relative;
    display: inline-flex;
    align-items: center;
    color: $very-dark-grey;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 8px;
    font-size: 13px;

    &:after{
        content: '';
        display: inline-block;
        width: 80px;
        height: 2px;
        margin-left: 10px;
        background-color: $very-dark-grey;
        transition: transform 0.3s ease-out;

    }

    &:hover{
        color: inherit;

        &:after{
            transform: scaleX(0.5);
            transform-origin: left top;
        }
    }
}

.btn-link-white{
    @extend .btn-link;
    color: $white;

    &:after{
        background-color: $white;
    }
}

.link-icon{
    display: inline-flex;
    align-items: center;
    font-weight: 500;

    .item-icon{
        font-size: 14px;
    }

    .item-text{
        letter-spacing: 5px;
        text-transform: uppercase;
        font-size: 13px;
        line-height: 1;
        margin-left: 10px;
    }

    &.icon-right{
        .item-text{
            margin-left: 0;
            margin-right: 10px;
        }
    }

    @include media-breakpoint-down(md){
        .item-icon{
            font-size: 12px;
        }
        .item-text {
            font-size: 11px;
        }
    }
}
