.gform_anchor{
    display: none;
}
.gfield_label{
    display: none !important;
}
.gfield{
    margin-top: 0 !important;
}
.ginput_container{
    margin-bottom: 30px !important;
    input, textarea{
        border: 0;
        border-bottom: 1px solid $black;
        padding: 5px 8px !important;
        color: $black;
        font-weight: bold;
        letter-spacing: 1.2px !important;
        &::placeholder{
            color: $grey;
            font-weight: normal !important;
        }
    }
    textarea{
        border: 1px solid $black !important;
        padding: 10px 20px !important;
    }
}
.gform_footer{
    position: relative;
    width: 150px !important;
    padding: 0 !important;
    margin-top: 50px !important;
    margin-bottom: 50px !important;
    input{
        margin: auto !important;
        border: 0;
        letter-spacing: 9px;
        text-transform: uppercase;
        background: $white;
        cursor: pointer;
        position: relative;
    }

        &:after {
            content: "";
            width: 100px;
            height: 2px;
            background: $black;
            position: absolute;
            right: -100px;
            top: 50%;
            transform: translateY(-50%);
        }
}


#map {
    position: relative;
    width: 100%;
    height: 65vh;
    background-color: $black;
}