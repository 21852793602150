/*
|---------------------------------------------------------------
| FORMS
|---------------------------------------------------------------
| Customization of bootstrap classes
|
|
*/

//Label
.form-group{
  label{
    @each $property, $value in $label-form-control{
      #{$property}: $value;
    }
  }
}

//Input/textarea
.form-control{
  @each $property, $value in $input-default{
    #{$property}: $value;
  }

  &:focus{
    @each $property, $value in $input-focus{
      #{$property}: $value;
    }
  }

  //Placeholder
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    @each $property, $value in $input-placeholder{
      #{$property}: $value;
    }
  }
  ::-moz-placeholder { /* Firefox 19+ */
    @each $property, $value in $input-placeholder{
      #{$property}: $value;
    }
  }
  :-ms-input-placeholder { /* IE 10+ */
    @each $property, $value in $input-placeholder{
      #{$property}: $value;
    }
  }
  :-moz-placeholder { /* Firefox 18- */
    @each $property, $value in $input-placeholder{
      #{$property}: $value;
    }
  }

}


//Select default
select.form-control{
  @each $property, $value in $select-default{
    #{$property}: $value;
  }
}


//Select custom
select.custom-select{
  -webkit-appearance: none;
  -moz-appearance: none;
  @each $property, $value in $select-custom{
    #{$property}: $value;
  }
}

//Checkbox
.custom-control{
  @each $property, $value in $custom-control{
    #{$property}: $value;
  }
  &.custom-checkbox{
    //label
    .custom-control-label{
      @each $property, $value in $label-custom-control{
        #{$property}: $value;
      }
      &:before{
        content: '';
        @each $property, $value in $checkbox-indicator{
          #{$property}: $value;
        }
      }

      &:after{
        content: '';
        @each $property, $value in $checkbox-default{
          #{$property}: $value;
        }
      }
    }

    //indicator checked
    .custom-control-input:checked ~ .custom-control-label{
      &:before{
        content: '';
        @each $property, $value in $checkbox-indicator-active{
          #{$property}: $value;
        }
      }
    }

  }
}

//Radio
.custom-control{
  @each $property, $value in $custom-control{
    #{$property}: $value;
  }
  &.custom-radio{
    //label
    .custom-control-label{
      @each $property, $value in $label-custom-control{
        #{$property}: $value;
      }
      &:before{
        content: '';
        @each $property, $value in $radio-indicator{
          #{$property}: $value;
        }
      }

      &:after{
        content: '';
        @each $property, $value in $radio-default{
          #{$property}: $value;
        }
      }
    }

    //indicator checked
    .custom-control-input:checked ~ .custom-control-label{
      &::before{
        content: '';
        @each $property, $value in $radio-indicator-active{
          #{$property}: $value;
        }
      }
    }
  }
}

//Button toggle
.btn-group-toggle {
  label.btn{

    //Button toggle default
    @each $property, $value in $block-toggle-default {
      #{$property}: $value;
    }

    //Button toggle hover
    &:hover{
      @each $property, $value in $block-toggle-hover {
        #{$property}: $value;
      }
    }

    //Button toggle active
    &.active{
      @each $property, $value in $block-toggle-active {
        #{$property}: $value;
      }
    }

  }
}

//Block toggle
.block-toggle {
  label.btn{
    input{
      display: none;
    }

    //Block default
    @each $property, $value in $block-toggle-default {
      #{$property}: $value;
    }

    //Block hover
    &:hover{
      @each $property, $value in $block-toggle-hover {
        #{$property}: $value;
      }
    }

    //Block active
    &.active{
      @each $property, $value in $block-toggle-active {
        #{$property}: $value;
      }
    }

  }
}