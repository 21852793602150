/*
|---------------------------------------------------------------
| PATH
|---------------------------------------------------------------
| Define paths variables
|
|
*/

/*
|
| Paths
|----------------
|
*/
$path-img : '../img';
$path-fonts : '../fonts';