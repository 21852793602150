.univers-title{
    h1, h2{
        padding-top: 170px;
        padding-bottom: 170px;
        @include media-breakpoint-down(lg) {
            text-align: center;
        }
    }
    img{
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        z-index: -1;
        @include media-breakpoint-down(lg) {
            width: 100%;
            object-fit: cover;
        }
    }
}
.univers-text{
    column-count: 2;
    column-gap: 50px;
    @include media-breakpoint-down(lg) {
        column-count: 1;
    }
}
.univers-block{
    padding-top: 180px;
    padding-bottom: 180px;
    @include media-breakpoint-down(lg) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}
.gallery__container{
    img{
        width: 100%;
    }
}
.b-0{
    bottom: 0;
}
.univers-second{
    .container-fluid{
        padding: 0;
    }
    .blocks{
        width: calc(58% + 154px);
        z-index: 2;
        margin-left: -150px;
        @include media-breakpoint-down(lg) {
            margin: 0;
            width: 100%;
        }
        .image{
            width: 40%;
            position: absolute;
            right: 0;
            bottom: 0;
            transform: translateY(40%);
            @include media-breakpoint-down(lg) {
                position: relative;
                width: 100%;
                transform: none;
                margin-top: 30px;
            }
        }
    }
}