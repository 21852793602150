/*
|-----------------------
| 	  Slader Genius
|-----------------------
|
*/
[data-genius-wrapper] {
    position: relative;
}

[data-genius-loader]{
    width: 100%;
    height: 1px;
    background-color: #dedede;

    [data-genius-loader-inner]{
        position: relative;
        top: -0.5px;
        width: 100%;
        height: 2px;
        border-radius: 1px;
        background-color: $very-dark-grey;
        transform: scaleX(0);
    }
}