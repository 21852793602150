/*
|-----------------------
| 	    PRESS
|-----------------------
|
*/

#page-presse {
  h1 {
    @include media-breakpoint-down(md) {
      text-align: center;
      font-size: 28px;
    }
  }
  .zone-articles {
    height: calc(100vh - (90px + 120px));
  }
  .article-presse {
    width: 100%;
    max-width: 280px;
    margin: auto;
  }
}

.is-mobile {
  display: none;
  @include media-breakpoint-down(md) {
    display: block;
  }
}
.is-desktop {
  display: block;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.single {
  .cms {
    img {
      max-width: initial;
      width: 100%;
      height: inherit;
    }
  }
}