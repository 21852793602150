//------- DEFAULT
body{
  font-family: $font-family-default;
  color: $default-color;
  font-size: $default-font-size;
  line-height: $default-line-height;
  font-weight: $default-font-weight;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6{
  @each $property, $value in $default-titles{
    #{$property}: $value;
  }
}

a{
  display: inline-block;
  color:inherit;

  &:hover{
    color: $default-link-color-hover;
  }
}
.mt-neg50{
  margin-top:-100px;
  @include media-breakpoint-down(md) {
    margin-top:initial;
  }
}