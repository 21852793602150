/*
|---------------------------------------------------------------
| COLORS
|---------------------------------------------------------------
| Define colors
|
|
*/

/*
|
| Colors declaration
|----------------------
|
*/
$black:           #000000;
$light-black:     #232323;
$very-dark-grey:  #353131;
$dark-grey:       #777777;
$grey:            #a1a1a1;
$light-grey:      #dedede;
$very-light-grey: #F7F6F7;
$white:           #FFFFFF;
$white-opacity:   rgba(255, 255, 255, 0.1);
$black-opacity:   rgba(0, 0, 0, 0.4);
$light-blue:      #E7EAEF;

/*
|
| Assign colors
|---------------
|
*/
$border-color:                  $grey;
$text-color:                    $very-dark-grey;
$default-background-overlay:    $black-opacity;


/*
|-------------
| Colors map 
|-------------
| - How to use: add lines to create more "color classes" 
| - Generated classes: .bg-{color} & .color-{color}
|
*/
$colors : (
  //Required colors
  'black'          : $black,
  'light-black'    : $light-black,
  'very-dark-grey' : $very-dark-grey,
  'dark-grey'      : $dark-grey,
  'grey'           : $grey,
  'light-grey'     : $light-grey,
  'very-light-grey': $very-light-grey,
  'white'          : $white,
  'white-opacity'  : $white-opacity,
  'black-opacity'  : $black-opacity,
  'light-blue'     : $light-blue,
);



