/*
|-----------------------
| 	    BLOG
|-----------------------
|
*/

#page-blog {

    /*
    |
    | Section article
    |------------------
    */
    .section-article{
        .col-content {
            @extend .col-xl-6;
        }

        .col-img {
            @extend .col-xl-6;
        }

        &:nth-child(6n-5){
            .col-content{
                .item-content{
                    @include media-breakpoint-up(xl){
                        padding-right: 50px;
                    }
                }
            }
        }


        &:nth-child(6n-4),
        &:nth-child(6n){
            .col-content {
                @extend .order-xl-2;
            }

            .col-img{
                @extend .order-xl-1;
            }

            .col-content {
                .item-content {
                    @include media-breakpoint-up(xl) {
                        padding-left: 50px;
                    }
                }
            }

            .col-img .item-img {
                width: $custom-col-xl-6;
                @extend .stick-left;

                @include media-breakpoint-down(lg){
                    width: 100%;
                }
            }
        }

        &:nth-child(6n-3),
        &:nth-child(6n-1) {
            .col-img .item-img {
                width: $custom-col-xl-6;

                @include media-breakpoint-down(lg) {
                    width: 100%;
                }
            }

            .col-content {
                .item-content {
                    @include media-breakpoint-up(xl) {
                        padding-right: 50px;
                    }
                }
            }
        }

        &:nth-child(6n-2){
            .col-content {
                @extend .order-xl-2;
            }

            .col-img {
                @extend .order-xl-1;
            }

            .col-content {
                .item-content {
                    @include media-breakpoint-up(xl) {
                        padding-left: 50px;
                    }
                }
            }
        }
    }

    .loader{
        // position: absolute;
        z-index: 1;
        // top: calc(50% - 35px);
        // left: calc(50% - 35px);
        height: 70px;
        width: 70px;
        display: none;
        border: 3px solid $white;
        border-top: 3px solid $dark-grey;
        border-radius: 50%;
        animation: spin 1s linear infinite;

        margin: auto;
    }
}