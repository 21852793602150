/*
|---------------------
|       HEADER
|---------------------
*/

/*
|
| Helpers
|----------
*/
.optimize-animation {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
  perspective: 1000;
  -webkit-perspective: 1000;
  will-change: transform;
}

.bg-contain {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.title-xxl {
  font-size: 100px !important;
  @include media-breakpoint-down(sm) {
    font-size: 50px !important;
  }
}

.cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.r-0 {
  right: 0;
}

.fd-column {
  flex-direction: column;
}

.pe-n {
  pointer-events: none;
}

.pe-a {
  pointer-events: auto;
}

.no-result-full {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}

/*
|
| Loader
|---------
*/
.page-loader {
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;

  .item-content {
    .item-draw {
      position: relative;
      // width: 360px;

      .item-icon {
        width: 100%;
        animation: fadeInUp 1s ease-out;
      }

      .item-logo {
        // position: absolute;
        // top: 50%;
        // left: 50%;
        width: 480px;
        max-width: 100%;
        animation: slideInUp 1s ease-out;
        //animation-delay: 0.3s;
        // transform: translateX(-50%) translateY(-50%);

        img, svg {
          max-width: 350px;
          width: 100%;
          height: auto;
        }
      }
    }

    .loadbar {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 5px;
      background-color: lighten($very-light-grey, 10%);

      .item-inner {
        height: 100%;
        transform: scaleX(0);
        background-color: darken(#e3d1e5, 10%);
        animation: loadbar 2s ease-out;
        transform-origin: left top;
      }
    }
  }
}

@keyframes loadbar {
  from {
    transform: scaleX(0)
  }

  to {
    transform: scaleX(0.5)
  }
}

@keyframes slideInUp {
  from {
    transform: translateY(50px)
  }

  to {
    transform: translateY(0)
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(100px)
  }

  to {
    opacity: 1;
    transform: translateY(0)
  }
}

/*
|
| Stick Left
|--------------
*/
.stick-left {
  @include media-breakpoint-up(xl) {
    margin-left: calc(-1 * ((100vw - 1200px + 90px) / 2));
  }
}

/*
|
| Container fluid
|------------------
*/
.container-fluid {
  padding: 0 140px;

  &.container-fluid-sm {
    padding: 0 90px;
  }

  @include media-breakpoint-down(sm) {
    padding: 0 15px;
  }
}

.col-lg-6-5, .col-lg-5-5 {
  position: relative;
  width: 100%;
}

@include media-breakpoint-up(lg) {
  .col-lg-6-5 {
    flex: 0 0 52%;
    max-width: 52%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .col-lg-5-5 {
    flex: 0 0 48%;
    max-width: 48%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@include media-breakpoint-up(xl) {
  .col-xl-6-5 {
    flex: 0 0 52%;
    max-width: 52%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .col-xl-5-5 {
    flex: 0 0 48%;
    max-width: 48%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

// @media (min-width: 1200px) {
//     .container{
//         max-width: 1350px;
//     }
// }

/*
|
| Site container
|------------------
*/
.site-container {
  //padding-top: 150px;
}

.fw-bold {
  font-weight: 700;
}

.imagefit {
  position: relative;
  overflow: hidden;
  height: 100%;
  a {
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
    overflow: hidden;

    img {
      width: auto;
      min-width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  img {
    width: auto;
    min-width: 100%;
    height: auto;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    transform: translateX(-50%) translateY(-50%);
  }
}

/*
|
| Bg img
|----------
*/
.bg-img {
  position: relative;
  @extend .bg-cover;
  width: 100%;

  .item-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $black;
  }
}

.bg-img-sm {
  @extend .bg-img;
  height: 400px;
}

.bg-img-md {
  @extend .bg-img;
  height: 500px;

  &.portrait {
    height: 800px;
  }

  @include media-breakpoint-down(md) {
    height: 400px !important;

    &.portrait {
      height: 40vh !important;
    }
  }
}

.duo-bg {
  height: 590px!important;
}
@include media-breakpoint-down(lg) {
  .duo-bg {
    height: 490px!important;
  }
}

.bg-img-full {
  @extend .bg-img;
  height: 90vh;
}

/*
|
| Banner img
|-------------
*/
.banner-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 77vh;
  @extend .bg-cover;
}

/*
|
| Banner
|---------
*/
.banner {
  position: relative;
  @extend .bg-cover;
  display: flex;
  align-items: center;
  width: 100%;
  height: 80vh;

  .item-container {
    padding: 0 12%;
  }
}

/*
|
| Heading-sm
|-------------
*/
.headings {
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.heading-xs {
  @extend .headings;
  font-size: 10px;
}

.heading-sm {
  @extend .headings;
  font-size: 11px;
}

.heading-md {
  @extend .headings;
  font-size: 14px;
}

/*
|
| Swiper full
|--------------
*/
.swiper-full {
  position: relative;

  .arrows {
    position: absolute;

    .swiper-button-prev {
      left: 0;
    }
    .swiper-button-next {
      right: 0;
    }
  }
}

/*
|
| Video
|--------------
*/
.full-video {
  video {
    width: 100%;
    height: 70vh;
  }
  .plyr__controls {
    display: none;
  }
  .plyr__control--overlaid {
    background: transparent;
    border: 1px solid white;
    box-shadow: none;
    padding: 50px;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      border: 1px solid $very-light-grey;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      opacity: 0;
    }
    &:hover {
      &:before {
        animation: hoverplay 0.5s;
      }
    }
  }
  .plyr__poster {
    background-size: cover;
  }
}

/*
|
| Scroll area
|--------------
*/
.scroll-area {
  .scrollbar-track {
    &.scrollbar-track-y {
      width: 4px !important;

      .scrollbar-thumb {
        width: 100% !important;
        background: $black !important;
      }
    }
  }
}

/*
|
| Custom select
|----------------
*/
.c-select {
  width: 100%;
  height: 40px;
  border-radius: 0;
  padding-left: 10px;
  border: none;
  border-bottom: 1px solid $black;
  -webkit-appearance: none;
  background-color: $white;
  background-image: url('../img/icon/bottom.svg');
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;

  &:focus {
    outline: none !important;
  }
}

/*
|
| Bloc push
|-------------
*/
.bloc-push {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 150px 15px;
  @include media-breakpoint-down(md) {
    padding: 60px 15px;
  }

  .item-arrow {
    display: inline-block;
    margin-left: 40px;
    font-size: 45px;
    transition: all 0.3s ease-out;
  }

  &:hover {
    .item-arrow {
      transform: translateX(30px);
    }
  }
}

.back-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 10;

  width: 44px;
  height: 44px;

  background-color: white;
  background-image: url('../img/icons/left-arrow-black.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;

  transform: rotate(90deg);

  cursor: pointer;
}