/*
|-----------------------
| 	 IDENTITE MURALES
|-----------------------
|
*/

#page-identite-murale {

    /*
    |
    | Banner
    |---------
    */
    .section-banner{
        .item-pagination{
            width: 100%;
            display: flex;
            justify-content: space-between;
            border-top: 1px $light-grey solid; 

            .item-link {
                position: relative;
                padding-top: 20px;
                font-size: 13px;
                color: $light-grey;
                font-weight: 500;
                cursor: pointer;
                transition: all 0.4s ease-out;


                &.active {
                    &:before{
                        content: '';
                        position: absolute;
                        top: -1px;
                        left: 0;
                        width: 100%;
                        height: 2px;
                        background-color: $black;
                    }   
                    color: $black;
                }
            }
        }
    }
}