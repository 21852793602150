/*
|--------------------
|    Realisations
|--------------------
*/
#page-realisations {
    // display: flex;
    // flex-wrap: wrap;

    pre.dump {
        display: block;
        width: 100%;
        clear: both;
        color: #000 !important;
        background: #ccc !important;
        text-transform: none !important;
        word-break: break-all;
        word-wrap: break-word;
        white-space:pre-wrap;
      }

    /*
    |
    | Section left
    |---------------
    */
    .section-left{
        flex: 0 0 50%;

        .item-content{
            padding: 50px 75px;
            @media screen and (min-width: 1200px) and (max-width: 1465px) {
                padding: 50px 25px;
            }
        }

        @include media-breakpoint-down(lg) {
            flex: 0 0 100%;

            .realisations-list{
                display: none;
            }
        }

        @include media-breakpoint-down(sm) {
            .item-content {
                padding: 50px 15px;
            }
        }
    }

    /*
    |
    | Section right
    |----------------
    */
    .section-right {
        flex: 0 0 50%;

        @include media-breakpoint-down(lg){
            flex: 0 0 100%;
        }

        /*
        |
        | Scroll area
        |--------------
        */
        .realisations-container{
            position: relative;
            width: 100%;
            height: calc(100vh - 80px);
            background-color: $black;

            .scrollable-area{
                width: 100%;
                height: 100%;

                .scrollbar-track {
                    &.scrollbar-track-y {
                        width: 2px !important;

                        .scrollbar-thumb {
                            width: 100% !important;
                            background: $black !important;
                        }
                    }
                }

                @include media-breakpoint-down(lg){
                    height: auto;
                }
            }


            /*
            |
            | Bloc realisation
            |-------------------
            */
            .bloc-realisation {
                @extend .bg-cover;
                width: 100%;
                height: calc(100vh - 80px);
                display: flex;
                align-items: flex-end;

                .item-content {
                    padding: 0 140px 50px;
                }

                a:hover{
                    color: $white;
                    text-decoration: underline!important;
                }
            }
        }
    }

    @-webkit-keyframes spin {
        to {
            border-top-color: $dark-grey;
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes spin {
        to {
            border-top-color: $dark-grey;
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }



    padding: {
        top: 130px;
    }

    @include media-breakpoint-down(md) {
        padding: {
            top: 50px;
        }
    }

    .real-filters {
        padding: {
            top: 100px;
        }

        .c-select {
            border-width: 2px;
        }

        .filter-id {
            display: flex;
            align-items: center;
            justify-content: space-between;

            padding: {
                top: 100px;
            }

            .filter-item {
                position: relative;

                color: $grey;
                font-size: 16px;
                font-weight: 700;
                text-transform: capitalize;

                cursor: pointer;

                transition: color 0.3s ease-out;

                &:after {
                    content: '';

                    position: absolute;
                    left: 0;
                    right: 100%;
                    bottom: 0;

                    height: 2px;

                    background-color: $black;

                    transition: right 0.3s ease-out;
                }

                &.active {
                    color: $black;

                    &:after {
                        right: 0;
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            padding: {
                top: 50px;
            }

            .filter-id {
                flex-direction: column;

                padding: {
                    top: 50px;
                }

                .filter-item {
                    margin: {
                        top: 15px;
                    }
                }
            }
        }
    }


    .real-list {
        position: relative;

        margin: {
            top: 100px;
        }

        .container {
            @media (min-width: 1200px) {
                max-width: 1280px;
            }
        }

        .loader{
            // position: absolute;
            z-index: 1;
            // top: calc(50% - 35px);
            // left: calc(50% - 35px);
            height: 70px;
            width: 70px;
            display: none;
            border: 3px solid $white;
            border-top: 3px solid $dark-grey;
            border-radius: 50%;
            animation: spin 1s linear infinite;

            margin: auto;
        }

        .real-item {
            display: block;

            width: 100%;

            padding: {
                bottom: 50px;
            }

            .real-item-content {

            }

            .real-item-zoom {
                position: relative;

                overflow: hidden;

                .real-item-image {
                    width: 100%;

                    padding: {
                        top: 46%;
                    }

                    transition: transform .4s ease-out;

                    background-position: center bottom;
                    background-repeat: no-repeat;
                    background-size: cover;
                }

                &:hover {
                    .real-item-image {
                        transform: scale(1.075);
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            .real-item {
                padding: {
                    bottom: 75px;
                }
            }
        }
    }

}

.scrollable-area{
    overflow: scroll;
}