/*
|-----------------------
| 	    HOME
|-----------------------
|
*/

#page-home{
    .page-home-title {
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 8px;
        text-align: center;
        text-transform: uppercase;
    }

    .sidepage-link {
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;

        .item-text {
            position: relative;
            display: inline-block;
            text-transform: uppercase;
            letter-spacing: 10px;
            font-weight: 600;
            font-size: 13px;
            padding-top: calc(25px + 15px);

            &:before{
                content: '';
                position: absolute;
                top: 0;
                left: calc(50% - 1px);
                width: 2px;
                height: 25px;
                background-color: $black;
                transition: all 0.2s ease-out;
                transform-origin: top;
            }
        }

        &.sl-left {
            left: 0px;

            .item-text {
                transform: rotate(-90deg) translateX(-50%);
                transform-origin: left top;
            }
        }

        &.sl-right {
            right: 0px;

            .item-text {
                transform: rotate(90deg) translateX(50%);
                transform-origin: right top;
            }
        }

        &:hover{
            .item-text{
                &:before{
                    transform: scaleY(0);
                }
            }
        }

        @include media-breakpoint-down(sm){
            display: none;
        }
    }
    /*
    |
    | Slider Desktop
    |-----------------
    */
    #section-slider-desktop{
        /*
        |
        | Slider home
        |---------------
        */
        .container-fluid-sm {
            @include media-breakpoint-down(md) {
                padding: 0 15px;
            }
        }

        .genius-home {
            width: 100%;

            .layer {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                width: 40vw;
                height: 100%;
                background-color: $black;

                @include media-breakpoint-down(lg) {
                    display: none;
                }
            }

            .genius-slide {
                position: relative;
                z-index: 2;

                .item-img {
                    position: relative;
                    @extend .bg-cover;
                    width: 100%;
                    height: 77vh;
                    height: 70vh;

                    .white-layer {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(255, 255, 255, 0.5);
                    }

                    @include media-breakpoint-down(lg) {
                        height: 40vh;
                    }
                }

                .item-content {
                    padding-left: 70px;

                    @include media-breakpoint-down(lg) {
                        padding-left: 0 !important;
                    }

                    .item-title {
                        display: flex;

                        font-size: 90px !important;

                        @include media-breakpoint-down(lg) {
                            width: 100%;
                            margin-left: 0 !important;
                            justify-content: flex-start !important;
                        }

                        .item-number {
                            display: inline-block;
                        }
                    }
                }

                .col-img,
                .col-content {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }

                /*
                |
                | Size Img of position 1 & 3
                |-----------------------------
                */
                &.position-1,
                &.position-3 {
                    .col-img {
                        @extend .col-12,
                        .col-xl-6-5;
                    }

                    .col-content {
                        @extend .col-xl-5-5;
                    }
                }

                &.position-1,
                &.position-2 {}

                &.position-1 {
                    .item-content {
                        padding-left: 70px;

                        .item-title {
                            margin-left: -185px;

                            .item-number {
                                margin-right: 30px;
                            }
                        }
                    }
                }

                &.position-1{
                    .col-content {
                        .col-text {
                            @extend .col-xl-10, .offset-xl-2;
                        }
                    }
                }

                &.position-2 {
                    .col-img {
                        @extend .col-xl-4;
                    }

                    .col-content {
                        @extend .col-xl-5;

                        .col-text {
                            @extend .col-xl-12;
                        }
                    }
                }

                &.position-3 {
                    .col-content {
                        .col-text {
                            @extend .col-xl-12;
                        }
                    }
                }

                /*
            |
            | align col text of position : 2 & 3
            |------------------------------------
            */
                &.position-2,
                &.position-3 {
                    .col-img {
                        order: 2;

                        @include media-breakpoint-down(lg) {
                            order: 1;
                        }
                    }

                    .col-content {
                        order: 1;
                        text-align: right;

                        @include media-breakpoint-down(lg) {
                            order: 2;
                            text-align: left;
                        }

                        .item-content {
                            padding-right: 70px;

                            .item-title {
                                margin-right: -185px;
                                justify-content: flex-end;

                                .item-number {
                                    margin-left: 30px;
                                    order: 2;
                                }
                            }
                        }
                    }
                }
            }
        }

        .row-loader {
            margin: 40px 0 30px;

            @include media-breakpoint-down(lg) {
                margin: 40px 0 15px;
            }

            .genius-loader {
                background-color: $light-grey;
            }
        }


        .genius-home-pagination {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .item {
                font-size: 13px;
                color: $light-grey;
                font-weight: 500;
                cursor: pointer;
                transition: all 0.4s ease-out;

                @include media-breakpoint-down(lg) {
                    font-size: 10px;
                    text-align: center;

                }

                &.active {
                    color: $black;
                }
            }
        }
    }



    /*
    |
    | Slider mobile
    |-----------------
    */
    #section-slider-mobile{
        .swiper-home{
            .item-title{

            }
        }
        .item-img{
            position: relative;
            @extend .bg-cover;
            width: 100%;
            height: calc(100vh - 80px);

            .item-title{
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                background-color: $white;
                padding: 15px 0;
            }
        }

        @include media-breakpoint-down(sm) {
            .item-img {
                height: calc(85vh - 80px);
            }
        }
    }
}

.sidepage-link{
    @include media-breakpoint-down(lg) {
        display: none;
    }
}

.arrows{
    display: none;
    @include media-breakpoint-down(lg) {
        display: block;
        position: fixed;
        top: 50vh;
        width: 100%;
        z-index: 20;
        .swiper-button-prev {
            background-color: $white;
            background-image: url('../img/icons/left-arrow-black.svg');
            outline: none !important;
            box-shadow: none;
        }

        .swiper-button-next {
            background-color: $white;
            background-image: url('../img/icons/right-arrow-black.svg');
            outline: none !important;
            box-shadow: none;
        }
    }

}

