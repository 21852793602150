
.img-presentation {
  height: 100vh;
  @include media-breakpoint-down(lg) {
    height: 50vh;
  }
}

.item-banner {
  height: 60vh;
}

.profil {
  .inner {
    .section-characters {
      &:first-child {
        margin-top: -100px;

        @include media-breakpoint-down(md) {
          margin-top: 120px;
        }
      }

      &:nth-child(odd) {
        .row {
          .col-text {
            @extend .lg-up-pr-xl;
          }
        }
      }

      &:nth-child(even) {
        .row {
          flex-direction: row-reverse;

          .col-text {
            @extend .lg-up-pl-xl;
          }
        }
      }
    }

  }
}

.realisation {
  h2 {
    color: inherit;
    a {
      position: relative;
      color: inherit;
      &:after {
        content: "";
        position: absolute;
        width: 100px;
        height: 2px;
        background: $white;
        right: -150px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.duo-img {
  margin: 30px auto 0px auto;
  max-width: 440px;
  width: 100%;
}

/*
|
| Section video
|---------------
*/
@keyframes hoverplay {
  0% {
    opacity: 0;
    transform: (translateX(-50%) translateY(-50%) scale(1));
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: (translateX(-50%) translateY(-50%) scale(2));
  }
}

.videoiframe {
  iframe {
    width: 100% !important;
    height: 625px;
    display: block;
    @include media-breakpoint-down(md) {
      height: 524px;
    }
    @include media-breakpoint-down(sm) {
      height: 287px;
    }
    @include media-breakpoint-down(xs) {
      height: initial;
    }
  }
}