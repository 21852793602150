/*
|---------------------
|       Nav
|---------------------
*/

.nav-container{
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row-reverse;
    height: 100vh;
    @include media-breakpoint-down(lg) {
        display: block;
        //padding-top: 150px;
        width: 100%;
        left: 0;
    }
    .menu-container{
        padding: 0 100px 0 0px;
        @include media-breakpoint-down(lg) {
            padding: 0;
        }
    }

     .sub-menu-wrapper {
         padding: 0 100px 0 60px;
         @include media-breakpoint-down(lg) {
            padding: 0;
         }
     }

    /*
    |
    | Menu container
    |-----------------
    */
    .menu-container{
        height: 100%;
        background-color: $white;
        padding-top: 120px;
        overflow: scroll;
        @include media-breakpoint-down(lg) {
            //padding: 0;
            width: 100%;
            padding-top: 80px;
        }
        .menu{
            .mobile-display-sub-menu{
                    display: none;
                    @include media-breakpoint-down(lg) {
                        // display: block;
                        // position: absolute;
                        // right: 30px;
                        // top: 25px;
                        // width: 16px;
                    }
                }
            & > li{
                @include media-breakpoint-down(lg) {
                    position: relative;
                }
                & > a{
                    font-weight: 700;
                    font-size: 50px;
                    padding: 35px 0 35px 60px;
                    line-height: 1;
                    @include media-breakpoint-down(lg) {
                        font-size: 30px;
                        padding: 15px 0 0;
                        text-align: center;
                        width: 100%;
                    }
                }
            }

            .sub-menu{
                display: none;

                li{
                    text-align: center;
                }

                @include media-breakpoint-down(lg) {
                    display: block;
                }
            }

            @include media-breakpoint-down(lg) {
                display: flex;
                flex-direction: column;
                justify-content: center;

                height: 100%;
            }
        }
    }


    /*
    |
    | Sub Menu container
    |---------------------
    */
    .sub-menu-container{
        position: relative;
        width: 400px;
        height: 100%;

        .sub-menu-wrapper{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: $very-dark-grey;

            .item-line{
                position: absolute;
                right: -30px;
                width: calc(100% - 30px);
                height: 2px;
                background-color: $grey;
            }
            .sub-menu {
                margin-top: 30px;

                li {
                    &:not(:last-child) {
                        margin-bottom: 20px;
                    }

                    a {
                        font-size: 18px;
                        color: $grey;
                        transition: all 0.2s ease-out;

                        &:hover {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}


//mobile submenu


.subActive {
    display: block !important;
    li{
        text-align: center;
    }
}