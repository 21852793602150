/*********************
 *
 * DEFAULT STYLES
 *
 ********************/
.extra-slider {
    position: relative;
}

.extra-slider-slide {
    align-content: stretch;
    display: flex;
    flex-wrap: nowrap;
}

.extra-slider-slide.extra-slider-direction-y {
    flex-direction: column;
}

.extra-slider-processed .extra-slider-item {
    display: block;
    flex: 0 0 auto;
    list-style: none;
    position: relative;
}

.extra-slider-fade,
.extra-slider-curtain {
    display: flex;
    flex-wrap: nowrap;
}

.extra-slider-fade .extra-slider-item,
.extra-slider-curtain .extra-slider-item {
    flex: 0 0 auto;
    position: relative;
}

.extra-slider.extra-slider-curtain .extra-slider-item {
    margin: 0;
    overflow: hidden;
    width: 100%;
}

.extra-slider-wrapper{
    overflow: hidden;
}