/*
|
| SINGLE REALISATIONS
|----------------------
*/

#page-single-realisation {

    /*
    |
    | Swiper réalisation
    |---------------------
    */
    .swiper-realisation{
        position: relative;
        padding: 0 100px;

        @include media-breakpoint-down(sm) {
            padding: 0 15px;
        }

        .arrows{
            display: none;
            @include media-breakpoint-down(lg) {
                display: flex;
                justify-content: space-between;
                position: static;
                // top: 50%;
                width: 100%;
                z-index: 20;
                .swiper-button-prev {
                    position: static;
                    // transform: scale(.7);
                    background-color: $white;
                    background-image: url('../img/icons/left-arrow-black.svg');
                    background-size: 30px 20px;
                    outline: none !important;
                    box-shadow: none;
                    left: 0;
                    margin: 0;
                }

                .swiper-button-next {
                    position: static;
                    // transform: scale(.7);
                    background-color: $white;
                    background-image: url('../img/icons/right-arrow-black.svg');
                    background-size: 30px 20px;
                    outline: none !important;
                    box-shadow: none;
                    right: 0;
                    margin: 0;
                }
            }
        }

        /*
        |
        | Bloc container
        |------------------
        */
        .bloc-container{
            position: relative;
            width: 100%;
            height: calc(100vh - 200px);

            display: flex;
            flex-direction: column;

            .swiper-container {
                width: 100%;
                flex: 1;

                .swiper-wrapper {
                    max-height: 100%;
                }

                .swiper-slide {
                    overflow: hidden;
                    width: 100%;
                    // height: calc(100vh - 200px);
                    height: 100%;
                    background-color: $white;

                    .inner-slide {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        background-size: cover;
                        background-position: center;

                        &.landscape {
                            @extend .bg-cover;
                            background-position: center bottom !important;
                        }

                        &.portrait {
                            @extend .bg-contain;
                        }

                        img {
                            display: none;
                        }
                    }
                }

                .btn-container {
                    position: absolute;
                    left: 20px;
                    right: 20px;
                    bottom: 20px;
                    z-index: 10;

                    margin: 0 auto;

                    color: $white;
                    text-align: center;

                    .link-icon {
                        color: $white;

                        &:hover {
                            color: $light-grey;
                        }
                    }

                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }
            }


            h1 {
                // position: absolute;
                // z-index: 2;
                text-align: center;
                // width: 100%;
                // top: 50%;
                // transform: translateY(-50%);
            }


            /*
            |
            | Bloc description
            |-------------------
            */
            .bloc-description {
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.6);
                color: $white;
                display: flex;
                align-items: center;
                padding: 0 110px;

                @include media-breakpoint-down(lg){
                    padding: 0 60px;
                }

                @include media-breakpoint-down(sm) {
                    display: block;
                    padding: 30px;

                    display: none;
                }

                .item-close {
                    position: absolute;
                    z-index: 1;
                    top: 30px;
                    right: 30px;
                    width: 40px;
                    height: 40px;
                    color: $white;
                    font-size: 30px;
                    cursor: pointer;

                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }

                .item-content {
                    display: flex;
                    align-items: center;

                    .item-cols-container {
                        border-left: 1px solid $white;
                        padding-left: 40px;
                        margin-left: 40px;
                    }

                    @include media-breakpoint-down(lg){
                        display: block;

                        .item-cols-container{
                            margin-left: 0px;

                            .item-col{
                                &:not(:last-child){
                                    margin-bottom: 15px;
                                }
                            }
                        }
                    }

                    @include media-breakpoint-down(sm) {
                        .item-cols-container {
                            padding-left: 20px;
                        }
                    }
                }
            }


            @include media-breakpoint-down(sm) {
                height: auto;

                .swiper-container {
                    .swiper-slide {
                        height: auto;

                        .inner-slide {
                            position: static;

                            width: auto;
                            height: auto;

                            background-image: none;

                            text-align: center;

                            img {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }

        .bloc-description-mobile {
            @include media-breakpoint-up(md) {
                display: none;
            }
        }

        /*
        |
        | footer
        |---------
        */
        .realisation-footer{
            .footer-container{
                position: relative;
                width: 100%;
                height: 120px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .swiper-controls{
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                display: flex;

                a{
                    font-size: 18px;
                    position: relative;

                    &:not(:last-child){
                        margin-right: 10px;
                    }
                }

                .playpause {
                    margin-right: 10px;
                    width: 18px;
                    .btn-play{
                        visibility: hidden;
                    }
                    .btn-pause{
                    }
                    span {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;

                        text-align: center;
                    }
                }

                // .link-icon {
                //     .item-icon {
                //         font-size: 13px !important;
                //     }

                //     .item-text {
                //         font-size: 12px !important;
                //     }
                // }

                @include media-breakpoint-down(md){
                    a {
                        font-size: 14px;
                    }
                }



            }

            @include media-breakpoint-down(sm){
                .footer-container {
                    > .link-icon {
                        flex: 1;

                        .item-text {
                            letter-spacing: 2px;
                        }
                    }
                }

                .swiper-controls {
                    position: static;

                    transform: none;

                    margin: 0 10px;
                }
            }
        }


    }


}