/*
|---------------------
|       HEADER
|---------------------
*/
.header{
    &.header-sm{
        .header-container {
            height: 80px;

            .logo-container{
                svg, img{
                    width: 125px;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .header-container {
            height: 80px !important;

            .logo-container{
                svg, img{
                    width: 125px !important;
                }
            }
        }
    }

    .header-container{
        @extend .container-fluid;
        //position: fixed;
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 120px;
        background-color: transparent;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 1000;

        /*
        |
        | Logo
        |--------
        */
        .logo-container{
            @extend .absolute-center;

            svg, img{
                width: 225px;
            }

            @include media-breakpoint-down(sm) {
                position: static !important;

                transform: none !important;
            }
        }

        /*
        |
        | Right
        |---------
        */
        .header-right {
            display: flex;
            align-items: center;

            /*
            |
            | Burger
            |--------
            */
            .btn-menu{
                display: flex;
                align-items: center;
                margin-right: 20px;

                .item-text{
                    text-transform: uppercase;
                    font-weight: 500;
                    letter-spacing: 10px;
                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }

                .item-burger{
                    display: inline-flex;
                    justify-content: flex-end;
                    flex-wrap: wrap;
                    margin-right: 10px;
                    width: 20px;

                    &>span {
                        width: 100%;
                        height: 2px;
                        background-color: $dark-grey;
                        border-radius: 2px;

                        &:nth-child(2) {
                            width: 80%;
                            margin: 5px 0;
                        }
                    }
                }
            }

            /*
            |
            | Social
            |---------
            */
            .social-link{
                font-size: 30px;

                &:not(:last-child) {
                    margin: {
                        right: 20px;
                    }
                }
            }
        }
    }
}