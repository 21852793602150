//------- FONTS

// 100    Extra Light or Ultra Light
// 200    Light or Thin
// 300    Book or Demi
// 400    Normal or Regular
// 500    Medium
// 600    Semibold, Demibold
// 700    Bold
// 800    Black, Extra Bold or Heavy
// 900    Extra Black, Fat, Poster or Ultra Black

@font-face {
    font-family: 'helvetica-neue';
    src: url('../fonts/helvetica-neue/helveticaneuelt-webfont.eot');
    src: url('../fonts/helvetica-neue/helveticaneuelt-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/helvetica-neue/helveticaneuelt-webfont.woff2') format('woff2'),
        url('../fonts/helvetica-neue/helveticaneuelt-webfont.woff') format('woff'),
        url('../fonts/helvetica-neue/helveticaneuelt-webfont.ttf') format('truetype'),
        url('../fonts/helvetica-neue/helveticaneuelt-webfont.svg#helveticaneuelight') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'helvetica-neue';
    src: url('../fonts/helvetica-neue/helveticaneue_medium-webfont.eot');
    src: url('../fonts/helvetica-neue/helveticaneue_medium-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/helvetica-neue/helveticaneue_medium-webfont.woff2') format('woff2'),
        url('../fonts/helvetica-neue/helveticaneue_medium-webfont.woff') format('woff'),
        url('../fonts/helvetica-neue/helveticaneue_medium-webfont.ttf') format('truetype'),
        url('../fonts/helvetica-neue/helveticaneue_medium-webfont.svg#helveticaneuemedium') format('svg');
    font-weight: 500;
    font-style: normal;

}

@font-face {
    font-family: 'helvetica-neue';
    src: url('../fonts/helvetica-neue/helveticaneuebd-webfont.eot');
    src: url('../fonts/helvetica-neue/helveticaneuebd-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/helvetica-neue/helveticaneuebd-webfont.woff2') format('woff2'),
        url('../fonts/helvetica-neue/helveticaneuebd-webfont.woff') format('woff'),
        url('../fonts/helvetica-neue/helveticaneuebd-webfont.ttf') format('truetype'),
        url('../fonts/helvetica-neue/helveticaneuebd-webfont.svg#helveticaneuebold') format('svg');
    font-weight: 700;
    font-style: normal;

}